import Flickity from "flickity"

document.addEventListener("turbolinks:load", function() {
    if (document.getElementById("process-text-step1")) {
        document.getElementById("process-text-step1").onmouseover = function(){
             $("#process-image-img-1").removeClass("d-none");
             $("#process-image-img-2").addClass("d-none");
             $("#process-image-img-3").addClass("d-none");
             $("#process-image-img-1-mobile").removeClass("d-none");
             $("#process-image-img-2-mobile").addClass("d-none");
             $("#process-image-img-3-mobile").addClass("d-none");
         };
        document.getElementById("process-text-step2").onmouseover = function(){
             $("#process-image-img-1").addClass("d-none");
             $("#process-image-img-2").removeClass("d-none");
             $("#process-image-img-3").addClass("d-none");
             $("#process-image-img-1-mobile").addClass("d-none");
             $("#process-image-img-2-mobile").removeClass("d-none");
             $("#process-image-img-3-mobile").addClass("d-none");
         };
        document.getElementById("process-text-step3").onmouseover = function(){
             $("#process-image-img-1").addClass("d-none");
             $("#process-image-img-2").addClass("d-none");
             $("#process-image-img-3").removeClass("d-none");
             $("#process-image-img-1-mobile").addClass("d-none");
             $("#process-image-img-2-mobile").addClass("d-none");
             $("#process-image-img-3-mobile").removeClass("d-none");
       };
     }
    
   var elem = document.querySelector('.main-carousel');
   if (elem != undefined) {
       var flkty = new Flickity(elem, {
         // options
         cellAlign: 'left',
         contain: true
       });
    }
});
